import React from 'react'

export default function ContactTable() {
  return (
    <div className='flex w-full flex-col py-4 w-full'>
        <div className='flex justify-end w-full '>
            
                <button className='text-blue-700 rounded-full px-8 py-1.5'
                    style={{background: "rgba(236, 235, 254, 1)"}}
                >
                Add New Contact
                </button>
        </div>
        <div>
            
        </div>


    </div>
  )
}
 